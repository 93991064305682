<template>
  <form
    @submit.prevent="submit"
    enctype="multipart/form-data"
  >
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.name.value"
          :disabled="loading || loadingLang.es"
          :label="$t('layout.form.nameInput')"
          :error="!!form.name.errors.length"
          :error-messages="translatedErrors(form.name.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.name_pt.value"
          :disabled="loading || loadingLang.pt"
          :label="$t('layout.form.namePtInput')"
          :error="!!form.name_pt.errors.length"
          :error-messages="translatedErrors(form.name_pt.errors)"
          @blur="validate('name')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.name_en.value"
          :disabled="loading || loadingLang.en"
          :label="$t('layout.form.nameEnInput')"
          :error="!!form.name_en.errors.length"
          :error-messages="translatedErrors(form.name_en.errors)"
          @blur="validate('name')"
        />
      </div>
    </div>

    <div class="row">
      <div clasS="flex xs12">
        <va-button
          small
          type="button"
          color="primary"
          v-if="currentUser.can('Translate', 'translate')"
          :disabled="loading || loadingLang.pt || loadingLang.en"
          @click.prevent="translateMissing()"
        >
          <text-loading
            :loading="loadingLang.pt || loadingLang.en"
            icon="entypo entypo-language"
          >
            {{ $t('layout.translation') }}
          </text-loading>
        </va-button>
      </div>
    </div>
    <hr>

    <div class="row">
      <div class="flex xs12">
        <h6>{{ $t('trainings.types.inputs.certificateImageInput') }}</h6>
        <va-file-upload
          type="single"
          v-model="imagePath"
        />
      </div>
      <div class="flex xs12">
        <va-checkbox
          :label="$t('trainings.types.delete_template')"
          :disabled="loading"
          v-model="deleteDocument"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMixin } from '@/services/validator'
import { mapGetters } from 'vuex'

export default {
  name: 'training-types-form',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    trainingType: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      deleteDocument: false,
      imagePath: [],
      loadingLang: {
        es: false,
        pt: false,
        en: false,
      },
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        name_pt: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        name_en: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
  },
  watch: {
    trainingType (val) {
      this.setTrainingType(val)
    },
    imagePath (val) {
      this.filesChange()
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setTrainingType (val) {
      this.setFormData(val)

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.trainingType) {
        this.setTrainingType(this.trainingType)
      }
    },
    exceedSize () {
      const maxBytes = 8388608
      return this.imagePath[0].size > maxBytes
    },
    invalidFormat () {
      const validType = 'image/svg'
      return !this.imagePath[0].type.includes(validType)
    },
    filesChange () {
      if (this.imagePath.length === 0) {
        return
      }
      if (this.imagePath.length > 1) {
        this.imagePath = [this.imagePath.pop()]
        return
      }

      if (this.invalidFormat()) {
        this.showToast(this.$t('notifications.network.invalid_format', { format: 'svg' }), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 30000,
          fullWidth: false,
        })
        this.imagePath = []
        return
      }
      if (this.exceedSize()) {
        this.showToast(this.$t('notifications.network.file_size', { size: '8' }), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 30000,
          fullWidth: false,
        })
        this.imagePath = []
      }
    },
    checkEmpty (field) {
      return field === undefined || field === null || field === ''
    },
    async translateMissing () {
      let from = null
      let text = null

      const esText = this.getFormValue('name')
      const enText = this.getFormValue('name_en')
      const ptText = this.getFormValue('name_pt')
      if (!this.checkEmpty(esText)) {
        from = 'es'
        text = esText
      } else if (!this.checkEmpty(ptText)) {
        from = 'pt'
        text = ptText
      } else if (!this.checkEmpty(enText)) {
        from = 'en'
        text = enText
      } else {
        // console.log('All names empty')
        return
      }

      if (this.checkEmpty(esText)) {
        this.translate(from, 'es', text)
      }
      if (this.checkEmpty(ptText)) {
        this.translate(from, 'pt', text)
      }
      if (this.checkEmpty(enText)) {
        this.translate(from, 'en', text)
      }
    },
    async translate (from, lang, text) {
      this.loadingLang[lang] = true

      const body = {
        text: text,
        from: from,
        to: lang,
      }
      let response = false
      try {
        response = await this.$http.post('/translate', body)
      } catch (err) {
        this.loadingLang[lang] = false
        this.showToast(this.$t('notifications.translate.error'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return
      }

      const translation = response.data.data || []
      if (translation.length > 0) {
        const tag = lang !== 'es' ? `name_${lang}` : 'name'
        const value = translation[0].translations[0].text
        this.setFormValue(tag, value)
        this.validateAll()
      }
      this.loadingLang[lang] = false
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const trainingType = new FormData()
      if (this.trainingType.id) {
        trainingType.append('id', this.trainingType.id)
      }
      trainingType.append('name', this.getFormValue('name'))
      trainingType.append('name_pt', this.getFormValue('name_pt'))
      trainingType.append('name_en', this.getFormValue('name_en'))
      if (this.imagePath.length > 0) {
        trainingType.append('image_path', this.imagePath[0])
      }
      if (this.deleteDocument) {
        trainingType.append('delete', this.deleteDocument)
      }
      this.$emit('submit', trainingType)
    },
  },
}
</script>
